import { useTranslation } from 'next-i18next'

import { useRouter } from 'next/router'

import ReactDOM from 'react-dom'

import { useIsMobile } from '@/utils/useMobile'

import PopupOverlay from './PopupOverlay'
import { usePopup } from './hooks/usePopup'

export default function PopupOverlayView() {
  const { t: translatePopups } = useTranslation('popups')
  const { isPopupOpen, setIsPopupOpen } = usePopup({
    key: 'isPopupOpen'
  })

  const router = useRouter()
  const { isMobile } = useIsMobile()
  const popupElement = isPopupOpen && (
    <PopupOverlay
      mainText={translatePopups('popup.text.space')}
      secondaryTextOnImage={translatePopups('popup.text.description')}
      image={
        isMobile
          ? 'https://s3.eu-central-1.amazonaws.com/cdn.naviparking.com/digital_parking/landing/popup_mobile_2.png'
          : 'https://s3.eu-central-1.amazonaws.com/cdn.naviparking.com/digital_parking/landing/popup_3.png'
      }
      onClose={() => {
        setIsPopupOpen(false)
        localStorage.setItem('isPopupOpen', 'true')
      }}
      onDiscover={() => {
        router.push('/park-and-fly')
        setIsPopupOpen(false)
        localStorage.setItem('isPopupOpen', 'true')
      }}
    />
  )
  return typeof window !== 'undefined' ? (
    document.getElementById('popup-wrapper') &&
      ReactDOM.createPortal(
        popupElement,
        document.getElementById('popup-wrapper')!
      )
  ) : (
    <></>
  )
}
