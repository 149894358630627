import React from 'react'

import { useTranslation } from 'next-i18next'

import Button from '../Button/Button'

import {
  PopupWrapperOverlay,
  PopupContentOverlay,
  PopupImageOverlay,
  PopupTextContainer,
  PopupMainText,
  PopupSecondaryTextOnImage,
  ButtonsWrapper
} from './Popup.styled'

interface PopupOverlayProps {
  mainText: string
  secondaryTextOnImage: string
  image: string
  onClose: () => void
  onDiscover: () => void
}

const PopupOverlay: React.FC<PopupOverlayProps> = ({
  mainText,
  image,
  secondaryTextOnImage,
  onClose,
  onDiscover
}) => {
  const { t } = useTranslation('popups')

  return (
    <PopupWrapperOverlay data-test-id="popup-overlay">
      <PopupContentOverlay data-test-id="popup-wrapper">
        <PopupImageOverlay src={image} alt="Popup Background" />
        <PopupTextContainer>
          <PopupMainText dangerouslySetInnerHTML={{ __html: mainText }} />
          <PopupSecondaryTextOnImage>
            {secondaryTextOnImage}
          </PopupSecondaryTextOnImage>
        </PopupTextContainer>
        <ButtonsWrapper>
          <Button
            label={t('popup.button.close')}
            onClick={onClose}
            buttonType="close"
            dataTestId="popup-close-button"
          />
          <Button
            label={t('popup.button.discover')}
            onClick={onDiscover}
            buttonType="discover"
            dataTestId="popup-confirm-button"
          />
        </ButtonsWrapper>
      </PopupContentOverlay>
    </PopupWrapperOverlay>
  )
}

export default PopupOverlay
